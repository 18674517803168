import request from '@/utils/request'

// 获取验证码
export function getCodeImg(data) {
  return request({
    url: '/mobile/login/sendAuthCode',
    method: 'POST',
    data: data,
  })
}

// 获取微信登录用户信息(微信)
export function get_User_Info(data) {
  return request({
    url: '/mobile/auth/findSnsApiUserInfo',
    method: 'POST',
    data: data
  })
}
// 获取数据库中用户信息
export function get_User_InfoOwn(data) {
  return request({
    url: 'mobile/login/findUserInfo',
    method: 'POST',
    data: data
  })
}
// 保存登录的用户信息
export function save_User_Info(data) {
  return request({
    url: '/mobile/login/saveUserInfo',
    method: 'POST',
    data: data
  })
}
// 修改用户数据
export function alter_User_Info(data) {
  return request({
    url: '/mobile/login/updateUserInfo',
    method: 'POST',
    data: data
  })
}
// 查询商户系统浏览商品设置
export function get_merchant_set(data) {
  return request({
    url: '/mobile/common/queryBrowsingConfigInfo',
    method: 'POST',
    data: data
  })
}
// 查询当前用户需要手签的卡
export function get_card_info(data) {
  return request({
    url: '/mobile/common/getInfoByUserTele',
    method: 'POST',
    data: data
  })
}
// 获取appID
export function get_appid(data) {
  return request({
    url: '/mobile/wechatpay/findWechatConfigData',
    method: 'POST',
    data: data
  })
}
// 获取用户卡
export function get_card_config_info(data) {
  return request({
    url: '/mobile/common/getInfoByUserTele',
    method: 'POST',
    data: data,
  })
}
// 获取页面titlename
export function get_titlename(data) {
  return request({
    url: '/mobile/wechatpay/findTitleNameByCardNo',
    method: 'POST',
    data: data
  })
}