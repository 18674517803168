import "@babel/polyfill";
import Es6Promise from "es6-promise";
require("es6-promise").polyfill();
Es6Promise.polyfill();
import "@/styles/index.css";
import "@/styles/hotproduct.css";
import '@/styles/vue-quill.snow.css'
import "../public/static/font/font.css";
import "@/styles/ticketsproducts.css";
import { isWechatBrowser } from "@/utils/utils";
import { createApp } from "vue";
import VueJsonp from "vue-jsonp";
import App from "./App.vue";
import $ from "jquery";
import router from "./router";
import store from "./store";
import "element-plus/theme-chalk/index.css";
import { ref } from "vue";

import vant, {
  Popup,
  Toast,
  Dialog,
  Swipe,
  SwipeItem,
  NoticeBar,
  Tab,
  Tabs,
  Picker,
  Search,
  Field,
  CellGroup,
  Stepper,
  List,
  PasswordInput,
  NumberKeyboard,
  Overlay,
  CountDown,
} from "vant";
import "vant/lib/index.css";
import "@vant/touch-emulator";
import {
  get_merchant_set,
  get_card_info,
  get_appid,
  get_User_InfoOwn,
  get_titlename,
} from "@/api/login";
import { is_card } from "@/api/card";
import Cookies from "js-cookie";
import { getUrlParams, getNumberConversion } from "@/utils/utils";
import { useStore } from "vuex";
// import Vconsole from 'vconsole'
const app = createApp();

const mobileSign = getUrlParams(location.href).mobileSign; //获取商户的code
// const mobileSign =location.href.split("mobileSign=")[1]

createApp(App)
  .use(store)
  .use(router)
  .use(vant)
  .use($)
  // .use(Popup)
  .use(Toast)
  .use(Dialog)
  // .use(Swipe)
  // .use(SwipeItem)
  // .use(NoticeBar)
  // .use(Tab)
  // .use(Tabs)
  // .use(Picker)
  // .use(Search)
  // .use(Field)
  // .use(CellGroup)
  // .use(Stepper)
  // .use(List)
  // .use(PasswordInput)
  // .use(NumberKeyboard)
  // .use(Overlay)
  .use(VueJsonp)
  // .use(CountDown)
  .mount("#app");

// 全局方法挂载
app.config.globalProperties.getNumberConversion = getNumberConversion;
console.log(process.env.NODE_ENV);

// 跳转后页面回顶部
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
router.beforeEach(async (to, from, next) => {
  console.log(from.name + "-->" + to.name);
  if (to.name !== 'index' && to.name !== 'login' && to.name !== 'agreement') {
    splitUrl()
  }
  const login = Cookies.get(`hxflogin${mobileSign}`) == "true" ? true : false;
  // 进入页面前判断该商户该页面下的浏览状态设置
  if (to.meta.requireAuth) {
    const params2 = {
      merchantCode: mobileSign,
      phoneNo: Cookies.get(`hxfuserphone${mobileSign}`),
      openId: Cookies.get(`hxfopenid${mobileSign}`),
    };
    const params = {
      mobileSign: mobileSign,
    };
    get_merchant_set(params).then((data) => {
      //判断商户系统设置的浏览状态，1是无需登录即可浏览商品，2是登录后即可浏览商品，3是登录绑卡即可浏览商品
      if (data.data.data.value == 1) {
        if (login) {
          get_User_InfoOwn(params2).then((res) => {
            if (res && res.data.data != null) {
              if (res.data.data.isUsable == 2) {
                Toast("该手机号不可用，请联系客服或更换手机号登录！");
              } else {
                next();
              }
            } else {
              Toast("用户信息获取错误，请重新登录微信或升级微信后重试！");
            }
          });
        } else {
          next();
        }
      } else if (data.data.data.value == 2) {
        if (login) {
          get_User_InfoOwn(params2).then((res) => {
            if (res && res.data.data != null) {
              if (res.data.data.isUsable == 2) {
                Toast("该手机号不可用，请联系客服或更换手机号登录！");
              } else if (res.data.data.isUsable == 1 || res.data.data) {
                next();
              }
            } else {
              Toast("用户信息获取错误，请重新登录微信或升级微信后重试！");
            }
          });
        } else {
          Toast(data.data.data.name);
          next({
            name: "index",
            query: {
              routeName: to.name,
              mobileSign: to.query.mobileSign,
            },
          });
        }
      } else if (data.data.data.value == 3) {
        if (login) {
          get_User_InfoOwn(params2).then((res) => {
            if (res && res.data.data != null) {
              if (res.data.data.isUsable == 2) {
                Toast("该手机号不可用，请联系客服或更换手机号登录！");
              } else {
                const val = {
                  phoneNo: Cookies.get(`hxfuserphone${mobileSign}`),
                  merchantCode: to.query.mobileSign,
                };
                // Cookies.set(`hxfuserid${state.loginForm.mobileSign}`, data.userId, {
                //   expires: 30,
                // });
                is_card(val).then((res) => {
                  if (res.data.data == "false") {
                    Toast("绑卡后可浏览商品！");
                    next({
                      name: "bind",
                      query: {
                        module: to.name,
                        mobileSign: to.query.mobileSign,
                      },
                    });
                  } else if (res.data.data == "true") {
                    next();
                  }
                });
              }
            } else {
              Toast("用户信息获取错误，请重新登录微信或升级微信后重试！");
            }

          });
        } else {
          // Toast(data.data.data.name)
          next({
            name: "index",
            query: {
              routeName: to.name,
              mobileSign: to.query.mobileSign,
            },
          });
        }
      }
    });
  } else {
    next();
  }
  if (process.env.NODE_ENV !== 'production') {  //测试环境需要，正式环境不需要
    next();
  }
});
// 跳转进登录页的时候截去链接中的code和state参数
router.afterEach((to, from) => {
  if (from.name == "login" && to.name !== "agreement") {
    splitUrl()
  }
});

const splitUrl = () => {
  if (location.href.indexOf("?code") != -1) {
    window.location.href =
      location.href.split("?code=")[0] + "#" + location.href.split("#")[1];
    // location.search=''
  }
}
const getAppId = () => {
  const cardNo = getUrlParams(location.href).cardNo || '0';
  const params = { mobileSign: mobileSign }
  get_appid(params).then((res) => {
    if (res.data.code == 200 && res.data.data) {
      localStorage.setItem(`hxSignName${mobileSign}`, res.data.data.signName);
      localStorage.setItem(`hxfLogo${mobileSign}`, res.data.data.logoH5);
      localStorage.setItem(`appid${mobileSign}`, res.data.data.appId);
      localStorage.setItem(`mapKey${mobileSign}`, res.data.data.mapKey);
      if (cardNo == '0') {
        const signName = localStorage.getItem(`hxSignName${mobileSign}`); //获取当前公众号的简称
        if (signName) {
          document.title = signName;
        } else {
          document.title = "";
        }
      } else {
        const cardparams = { cardNo: cardNo }
        get_titlename(cardparams).then((res) => {
          if (res.data.code == 200 && res.data.data) {
            document.title = res.data.data;
          }
          else {
            document.title = "";
          }
        })
      }


      const logo = localStorage.getItem(`hxfLogo${mobileSign}`); //获取当前公众号的logo
      let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.rel = "icon";
      if (logo && logo !== "undefined" && logo !== "null") {
        link.href = logo
      } else {
        link.href = "/favicon.ico"
      }
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }).catch(() => { })
}
if (isWechatBrowser()) {
  getAppId()
}