import request from "@/utils/request";
// 获取用户卡
export function get_card_info(data) {
  return request({
    url: "/mobile/movie/findCardInfoAll",
    method: "POST",
    data: data,
  });
}
// 获取卡列表
export function get_card(data) {
  return request({
    url: "/mobile/movie/findCardInfo",
    method: "POST",
    data: data,
  });
}
// 获取卡列表（新）2023.09.07
export function get_card_list(data) {
  return request({
    url: "/mobile/movie/findCardInfoBySelectType",
    method: "POST",
    data: data,
  });
}

// 输入卡号查询余额,扫码查余额
export function get_cardNo(data) {
  return request({
    url: "/mobile/card/findCardBalance",
    method: "POST",
    data: data,
  });
}
// 输入绑卡
export function bind_card_input(data) {
  return request({
    url: "/mobile/card/saveBindCardInfo",
    method: "POST",
    data: data,
  });
}
// 扫码绑卡
export function bind_card_scan(data) {
  return request({
    url: "/mobile/card/saveBindCardInfo",
    method: "POST",
    data: data,
  });
}
// 获取卡详情
export function get_card_detail(data) {
  return request({
    url: "/mobile/movie/findCardInfoByCardNo",
    method: "POST",
    data: data,
  });
}
// 扫码提货
export function get_pickup(data) {
  return request({
    url: "/mobile/cakes/findPickUpGoods",
    method: "POST",
    data: data,
  });
}

// 提货卡banner获取
export function get_banner(data) {
  return request({
    url: "/mobile/card/findCardProductBanner",
    method: "POST",
    data: data,
  });
}
// 通过卡号查询配置礼包信息
export function get_card_config(data) {
  return request({
    url: "/mobile/movie/findConfigurationInfoByCardNo",
    method: "POST",
    data: data,
  });
}

// 查询专区商品列表信息
export function get_precinct_products(data) {
  return request({
    url: "/mobile/movie/findZoneGoodsInfo",
    method: "POST",
    data: data,
  });
}

// 卡券手签图片转换为url
export function get_generate_image(data) {
  return request({
    url: "/mobile/upload/GenerateImage",
    method: "POST",
    data: data,
  });
}
// 卡券确认手签
export function get_confirmt_info(data) {
  return request({
    url: "/mobile/common/confirmInfo",
    method: "POST",
    data: data,
  });
}
// 验证卡券是否符合当前商品使用(废弃)
export function verification_scope(data) {
  return request({
    url: "/mobile/yanxuan/getCardScopePurchaseConditions",
    method: "POST",
    data: data,
  });
}
// 验证专区卡券是否符合当前商品使用
export function verifiy_prefecture_scope(data) {
  return request({
    url: "/mobile/yanxuan/verifyCardScopeConditions",
    method: "POST",
    data: data,
  });
}
// 验证卡券是否符合当前商品使用
export function verifiy_card_scope(data) {
  return request({
    url: "/mobile/yanxuan/verifyCardScopePurchaseInfo",
    method: "POST",
    data: data,
  });
}
// 查询用户是否绑卡
export function is_card(data) {
  return request({
    url: "/mobile/movie/findBindCardData",
    method: "POST",
    data: data,
  });
}
// 查询卡券消费记录
export function card_order(data) {
  return request({
    url: "/mobile/ebsOrder/findEbsOrderInfoByCardNo",
    method: "POST",
    data: data,
  });
}
// 卡延期验证
export function is_post_pone(data) {
  return request({
    url: "/mobile/movie/verification",
    method: "POST",
    data: data,
  });
}
//   支付信息
export function wx_pay(data) {
  return request({
    url: "/mobile/ebsOrder/wxPayCardDelayPayOrderData",
    method: "POST",
    data: data,
  });
}

// 适用范围跳转
export function to_page(data) {
  return request({
    url: "/mobile/movie/selectScopeUrlByCardNo",
    method: "POST",
    data: data,
  });
}
// 判断卡券是否为vip卡
export function is_vip(data) {
  return request({
    url: "/mobile/movie/getIsVipMark",
    method: "POST",
    data: data,
  });
}

// 判断兑换页面是否需要跳转
export function is_page_jumps(data) {
  return request({
    url: "/mobile/card/findPageTransferRouteByCardNo",
    method: "POST",
    data: data,
  });
}
// 传递计算电影卡券适用范围服务费方法参数
export function scopeServiceFeeByMovie(data) {
  return request({
    url: "/mobile/ebsOrder/scopeServiceFeeByMovie",
    method: "POST",
    data: data,
  });
}
// 传递计算商城卡券适用范围服务费方法参数
export function scopeServiceFeeBySuborder(data) {
  return request({
    url: "/mobile/ebsOrder/scopeServiceFeeBySuborder",
    method: "POST",
    data: data,
  });
}
// 获取网易商城使用范围服务费数据
export function scopeServiceFeeByOrderNo(data) {
  return request({
    url: "/mobile/ebsOrder/scopeServiceFeeByOrderNo",
    method: "POST",
    data: data,
  });
}

// 常规提货券——单个商品详情
export function oneGoodsDetail(params) {
  return request({
    url: `/mobile/cakes/goodsDetailByProductId/${params.merchantCode}/${params.productId}`,
    method: "GET",
    params: params,
  });
}
// 常规提货券——多个商品商品详情
export function manyGoodsDetail(params) {
  return request({
    url: `/mobile/cakes/listSubGoodsByProductId/${params.merchantCode}/${params.productId}`,
    method: "GET",
    params: params,
  });
}
// 常规提货券——祝福语图片
export function getBlessImage(params) {
  return request({
    url: `/mobile/card/listGreeting/${params.merchantCode}/${params.cardNo}`,
    method: "GET",
    params: params,
  });
}
// 常规提货券——获取商品类目
export function getItemCategory(params) {
  return request({
    url: `/mobile/cakes/listGoodsItemCategoryForCard/${params.merchantCode}/${params.cardNo}/${params.phoneNo}`,
    method: "GET",
    params: params,
  });
}