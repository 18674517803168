import { createStore } from 'vuex'

import {get_merchant_set} from '@/api/login'
import { getUrlParams } from '@/utils/utils'
export default createStore({
  state: {
    configInfo:"",
    configName:""
  },
  getters: {
  },
  mutations: {   
  },
  actions: {
    getConfigInfo () {
      const mobileSign=getUrlParams(location.href).mobileSign //获取商户的code
      const params = {
            mobileSign: mobileSign
      };
      get_merchant_set(params).then((data) => {
        this.state.configInfo = data.data.data.value
        this.state.configName = data.data.data.name
        // console.log(this.state.configInfo);
        // console.log(data.data.data.value);
      })
    }

  },
  modules: {
  }
})
